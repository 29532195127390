import React, { Component } from 'react'
import Video from './Video'
import Image from './Image'

import './css/Cover.css'

class Cover extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
        height: null
    }
  }

  setHeight(h){
    this.setState({
      height: h
    })
  }

  render() {
    let h = this.state.height
    h = h ? {maxHeight: h} : {}

    return (
      <div className="Cover" style={h}>
        {this.props.lang === 'de' ? <Video /> : <Image /> }
      </div>
    )
  }
}

export default Cover
