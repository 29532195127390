import React, { Component } from 'react'
import ServiceListDocumentBtn from './ServiceListDocumentBtn'
import ServiceListCallSupportBtn from './ServiceListCallSupportBtn'
import ServiceListSendMessageBtn from './ServiceListSendMessageBtn'
import ServiceListEnterMessageBtn from './ServiceListEnterMessageBtn'


import msgIcon from './svg/icon_send.svg'

import maximize from './svg/icon_maximize.svg'
import minimize from './svg/icon_minimize.svg'

var icons = []
icons['message'] = msgIcon

class ServiceList extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            maximized: false,
            fullHeight: this.props.height // -> funktion
        }
    }

    
    componentDidUpdate(){
        console.log(this.props.height)
    }

    isLandscape(){
      return window.innerHeight < window.innerWidth
    }


  render() {
    let height = (this.state.maximized ? this.props.height : this.props.height / this.props.listLength) + 'vh'
    height = this.props.hide ? '0' : height

    return (
      <div className="ServiceList flexItem" style={{height: height}}>
        <img className='resize' src={this.state.maximized ? minimize : maximize } alt='resize' onClick={ () => { 
            this.setState({ maximized: !this.state.maximized})
            window.setTimeout( () => 
            {
              this.props.ss.cover.setHeight( this.state.maximized ? '4vh' : '40vh')
              this.props.selector.setPaddingTop( this.state.maximized ? '10vh' : null)
              this.props.parent.setState({maximized: this.state.maximized ? this.props.i : -1}) 
            }, 40)
        } 
        } />
        <div className='ServiceListName'>
            {this.props.serviceGroupName}
        </div>
        <div className='ServiceListDescription'>
            Wählen Sie eine Aktion aus der Liste aus. Sie können Nachrichten verschicken, Dokumente öffnen, oder den Support kontaktieren.
        </div>
        {
          this.props.texte.map((id, i) => {
            switch (id.type) {
              case 'document': return <ServiceListDocumentBtn key={i} content={id}/>
              case 'message': return <ServiceListSendMessageBtn key={i} buttonIcon={icons[id.type]} text={id.text} type={id.type}/>
              case 'other': return <ServiceListEnterMessageBtn key={i} buttonIcon={icons[id.type]} text={id.text} type={id.type}/>
              case 'call': return <ServiceListCallSupportBtn key={i} text={id.text} />
              default: return <div>type <b>{id.type}</b> not supported</div>
            }
          })
        }

      </div>
    )
  }
}

export default ServiceList
