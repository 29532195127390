import React, { Component } from 'react'

import './css/ServiceDefault.css'

class ServiceDefault extends Component {
  render() {
    return (
      <div className="ServiceDefault">
        ServiceDefault (error)
      </div>
    )
  }
}

export default ServiceDefault
