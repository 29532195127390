import React, { Component } from 'react'
import ServiceListDetails from './ServiceListDetails'
import moreIcon from './svg/placeholder.svg'
import moreIconOn from './svg/icon_doton.svg'

class ServiceListEnterMessageBtn extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            selected: false
        }
    }

    render() {
        let content = {first: 'Speichern', second: 'Öffnen', intro: 'Dieses Dokument enthält wichtige Informationen zu diversen relevanten Themen.'}
        
        if( this.props.type === 'message' )
            content = {first: 'Bearbeiten', second: 'Senden', intro: 'Eine Nachricht mit dem Inhalt "' + this.props.text + '" senden oder vorher bearbeiten.'}

        if( this.props.type === 'call' )
            content = {first: 'SMS', second: 'Anruf', intro: 'Den für diesen Bereich zuständigen Techniker telefonisch kontaktieren.'}

        return (
            <div key={this.props.key} className='msgButton' onClick={ () => this.setState({ selected: !this.state.selected}) }>
                <div>
                  <div className='msgButtonIconContainer iconContainer'>
                    <img className='msgButtonIcon' src={this.props.buttonIcon} alt='service' />
                  </div>
                  <span className='msgButtonName'>
                    {this.props.text}
                  </span>
                  <div className='more iconContainer'>
                    <img className='msgButtonMore' src={this.state.selected ? moreIconOn : moreIcon} alt='service' />
                  </div>
                </div>
                <ServiceListDetails selected={this.state.selected} content={content}/>
              </div>
        )
    }
}

export default ServiceListEnterMessageBtn
