import React, { Component } from 'react'
import './css/Video.css'

import empty from './video/empty.mp4'
import empty_ from './video/empty.png'

import workers from './video/reencoding_shutterstock_videos_x1.mp4'
import workers_ from './video/workers.png'

import writes from './video/writes.mp4'
import writes_ from './video/writes.png'

class Video extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            play: true
        }
    }

    render() {
        let t = ( Math.floor(Date.now() / 1000) - 1573763300 ) % 3
        let v = t ? (t > 1 ? empty : writes ) : workers
        let p = t ? (t > 1 ? empty_ : writes_ ) : workers_
        let f = this.state.play ? {} : { filter: 'blur(9px)' }
        return (
            <div className='Video' >
                <video autoPlay loop muted playsInline  poster={p} ref='video' style={f} onClick = {
                    () => {
                        this.state.play ? this.refs.video.pause() : this.refs.video.play()
                        this.setState({
                            play: !this.state.play
                        })
                    }
                    }>
                    <source type="video/mp4" src={v} />
                    <p>Your browser does not support the video element.</p>
                </video>
            </div>
        )
    }
}

export default Video
