import React, { Component } from 'react'
import docIcon from './svg/icon_document.svg'

class ServiceListDocumentBtn extends Component {
    constructor(props, context) {
        super(props, context)
    }

    render() {
        return (
            <div key={this.props.key} className='msgButton' onClick={ () => window.open(this.props.content.url, '_blank') }>
                <div>
                  <div className='msgButtonIconContainer iconContainer'>
                    <img className='msgButtonIcon' src={docIcon} alt='service' />
                  </div>
                  <span className='msgButtonName'>
                    {this.props.content.text}
                  </span>
                </div>
            </div>
        )
    }
}

export default ServiceListDocumentBtn
