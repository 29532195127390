import React, { Component } from 'react'
import ServiceListGroup from './ServiceListGroup'

import './css/ServiceList.css'

class ServiceList extends Component {
  constructor(props, context) {
    super(props, context)
    
    this.serviceElements = ['Services'] 

    this.state = {
        maximized: -1,
        fullHeight: this.getFullHeight()
    }

    
  }
  
  componentDidMount(){
    var self = this
    if ('onorientationchange' in window) {
        window.addEventListener("orientationchange", function() {
          window.setTimeout( () =>
          {
            self.setState({   
                fullHeight: self.getFullHeight()
            })

            // portrait
            if( window.innerHeight > window.innerWidth ){

              if(self.serviceListGroup.state.maximized){
                self.props.ss.cover.setHeight('4vh')
                self.props.selector.setPaddingTop('10vh')
              }else{
                self.props.ss.cover.setHeight(null)
                self.props.selector.setPaddingTop(null)
              }
            }

            // landscape
            if( window.innerHeight < window.innerWidth ){
              self.props.ss.cover.setHeight(null)
              self.props.selector.setPaddingTop(null)
            }
          }
          , 40)
        }, false)
    }
  }

  getFullHeight(){
    return window.innerHeight > window.innerWidth ? this.serviceElements.length > 1 ? 60 : 90 : 100 
  }

  render() {

    let texte = [{ text: 'FAQ', type: 'document', url: 'https://cbcdn2.gp-static.com/uploads/product_manual/file/202/HERO3_Plus_Black_UM_ENG_REVD.pdf'  },
    { text: 'Bedienungsanleitung', type: 'document', url: 'https://cbcdn2.gp-static.com/uploads/product_manual/file/202/HERO3_Plus_Black_UM_ENG_REVD.pdf' },
    { text: 'Toner leer', type: 'message' },
    { text: 'Papier leer', type: 'message' },
    { text: 'Drucker reagiert nicht', type: 'message' },
    { text: 'Sonstiges Problem...', type: 'message' },
    { text: 'Support Anruf', type: 'call' }]

    let serviceElements = this.serviceElements

    return (
          serviceElements.map((id, i) => {
            return (
              <ServiceListGroup
                texte={texte} 
                key={i} i={i} 
                ss={this.props.ss}
                listLength={serviceElements.length} 
                hide={this.state.maximized >= 0 ? this.state.maximized !== i : false} 
                parent={this}
                selector={this.props.selector}
                height={this.state.fullHeight} 
                serviceGroupName={serviceElements[i]}
                ref={(e)=> this.serviceListGroup = e }/>
                
            )
          })

    )
  }
}

export default ServiceList
