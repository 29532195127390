import React, { Component } from 'react'
import services from './lang/Services'
import Service from './Service'
import ServiceList from './ServiceList'/* 
import ServiceMessage from './ServiceMessage'
import ServiceDocumentation from './ServiceDocumentation'
import ServicePhone from './ServicePhone'
import ServiceFAQ from './ServiceFAQ' */
import ServiceDefault from './ServiceDefault'
import './css/Selector.css'

const map = {
  'service-meldung' : 'msg',
  'anleitung'       : 'doc',
  'hotline'         : 'tel',
  'faq'             : 'faq',
  'leer'            : 'drop',
  'bestellen'       : 'cart',
  'message'         : 'msgDark'
}

const defaultTabs = ['leer', 'bestellen', 'message' ]


class Selector extends Component {
  constructor(props, context) {
    super(props, context)

    this.state ={
      paddingTop: null
    }

    this.lang = this.props.lang
    this.tabs = this.getTabs()
    this.tabs = this.tabs ? this.tabs.split('|') : defaultTabs
    this.pid  = this.getPid()
    this.url  = 'http://my-qr.io/nn_debug/?fromSS=true&type=nntype&pid=' + this.pid + '&tab='
  }

  setPaddingTop(h){
    this.setState({paddingTop: h})
  }

  render() {

    let servicePreviews = ['msg']
    let pt = this.state.paddingTop
    let padding = pt ? { paddingTop: pt } : {}

    // dispatcher
    return (
      <div className="Selector flexContainer" style={padding}>
      {
        this.renderServiceTabsFromUrl() ?
          this.tabs.map( (id, i) => {
            return <Service key={i} service={services[map[id]][this.lang]} i={ map[id] } url={ id === 'faq' ? '' : this.url + id }/>
          })
        :
          servicePreviews.map( (id,i) => {
              switch (id) {
                case 'msg': return <ServiceList key={i} selector={this} ss={this.props.ss}/>
              
                default: return <ServiceDefault key={i}/>
              }
          })
      }        
      </div>
    )
  }


  
  getTabs(){
    return this.getURLParameter('tabs')
  }


  getPid(){
    return this.getURLParameter('pid')
  }


  getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
  }


  renderServiceTabsFromUrl(){
    return Boolean(this.getTabs())
  }
}

export default Selector
