import React, { Component } from 'react'
import placeholder from './svg/icon_doton.svg'

class ServiceListDetails extends Component {

    render() {
        return (
            <div className='ServiceButtonDetailsContainer' style={ this.props.selected ? {} : {height: 0}}>
                <div className='msgButtonIconContainer iconContainer'>
                    <img className='msgButtonIcon IconPlaceholder' src={placeholder} alt='service' />
                </div>
                <div className='ServiceButtonSubgroup' >
                    <div className='ServiceButtonDescription'>
                        {this.props.content.intro}
                    </div>
                    <div className='ServiceButtonDetails'>
                        <span className='OpenButton Button' >{this.props.content.second}</span>
                        {
                            this.props.content.first ? 
                                <span className='SaveButton Button' >{this.props.content.first}</span>
                            :
                        ''
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default ServiceListDetails
