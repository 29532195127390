import React, { Component } from 'react'
import ServiceListDetails from './ServiceListDetails'



import call from './svg/icon_call_simple.svg'

class ServiceListCallSupportBtn extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            selected: false
        }
    }

    render() {
        let content = {first: 'SMS', second: 'Anruf', intro: 'Den für diesen Bereich zuständigen Techniker telefonisch kontaktieren.'}

        return (
            <div key={this.props.key} className='msgButton' onClick={ () => this.setState({ selected: !this.state.selected}) }>
                <div>
                  <div className='msgButtonIconContainer iconContainer'>
                    <img className='msgButtonIcon' src={call} alt='service' />
                  </div>
                  <span className='msgButtonName'>
                    {this.props.text}
                  </span>
                </div>
                <ServiceListDetails selected={this.state.selected} content={content}/>
              </div>
        )
    }
}

export default ServiceListCallSupportBtn

