import React, { Component } from 'react'
import ServiceListDetails from './ServiceListDetails'

class ServiceListSendMessageBtn extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            selected: false
        }
    }

    render() {
        let content = {second: 'Senden', intro: 'Eine Nachricht mit dem Inhalt "' + this.props.text + '" senden oder vorher bearbeiten.'}



        return (
            <div key={this.props.key} className='msgButton' onClick={ () => this.setState({ selected: !this.state.selected}) }>
                <div>
                  <div className='msgButtonIconContainer iconContainer'>
                    <img className='msgButtonIcon' src={this.props.buttonIcon} alt='service' />
                  </div>
                  <span className='msgButtonName'>
                    {this.props.text}
                  </span>
      
                </div>
                <ServiceListDetails selected={this.state.selected} content={content}/>
              </div>
        )
    }
}

export default ServiceListSendMessageBtn
